import React from "react";
import { graphql } from 'gatsby'
import ReactMarkdown from "react-markdown";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Notification from "../components/header/notification";
import Layout from "../layouts/headerFooterOnly";

export default ({
    data: {
        datoCmsBugBounty: { title, image, description, seoMetaTags, notificationPage },
        allDatoCmsNotification: { edges: notifications }
    }
}) => {
    return (
        <Layout>
            <HelmetDatoCms seo={seoMetaTags} />
            <Notification
                notifications={notifications}
                linkedPage={notificationPage}
            />
            <header className="main-header about">
                <div className="container">
                    <div className="video-page-container">
                        <h1 className="h-nofixedwidth">{title}</h1>
                        <div className="sm-spacing" />
                        <img src={image.url} className="tab-story-img" alt={image.alt} />
                        <div className="sm-spacing" />
                        <div>
                            <ReactMarkdown source={description} escapeHtml={false} />
                        </div>
                    </div>
                </div>
            </header>
        </Layout>
    );
};

export const query = graphql`
  query BugBountyQuery {
    datoCmsBugBounty {
      id
      title
      image {
        alt
        url
      }
      description
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      notificationPage {
        id
        page
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
